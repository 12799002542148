import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'src/components/Button';
import { Tooltip } from 'src/components/Tooltip';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import { IconChevronLeft, IconPlus } from 'src/icons';
import ScheduleSessionsForm from 'src/pages/schedule/ScheduleSessionsForm';
import { routes } from 'src/routes';

import {
  useSingleStudentAvailableFeaturesQuery,
  useSingleStudentDetailsQuery,
} from '../singleStudentApi';
import StudentHeaderLoading from './StudentHeaderLoading';
import StudentHeaderParameters from './StudentHeaderParameters';
import StudentHeaderProfile from './StudentHeaderProfile';

const StudentHeader = () => {
  const { id } = useParams();
  const { data: studentAvailableFeatures } = useSingleStudentAvailableFeaturesQuery(id!);
  const { data: currentStudent, isFetching } = useSingleStudentDetailsQuery(id!);
  const { isSm } = useBreakpoint('sm');
  const [isAddSessionOpen, setIsAddSessionOpen] = useState(false);
  const handleCloseAddSession = () => {
    setIsAddSessionOpen(false);
  };

  const handleOpenAddSession = () => {
    setIsAddSessionOpen(true);
  };
  return (
    <>
      {isAddSessionOpen && (
        <ScheduleSessionsForm studentId={id} handleClose={handleCloseAddSession} />
      )}
      <div className="h-auto w-full px-4 pb-6 sm:px-12 sm:pb-12 sm:pt-12">
        <Button
          preset="ghost"
          Icon={IconChevronLeft}
          className="mb-6 inline-flex pl-0"
          to={`${routes.students}`}
        >
          Back to All Students
        </Button>
        {!currentStudent || isFetching ? (
          <StudentHeaderLoading />
        ) : (
          <>
            <div className="flex flex-col sm:flex-row">
              <StudentHeaderProfile
                size="md"
                student={currentStudent}
                className="mb-4 mr-6 sm:mb-0"
              />
              <StudentHeaderParameters size="md" student={currentStudent} />
            </div>
          </>
        )}
        <Tooltip
          side="left"
          content={
            !studentAvailableFeatures?.canScheduleSession
              ? 'Sessions cannot be scheduled prior to goals selection.'
              : undefined
          }
          trigger="hover"
          className="w-72"
        >
          <Button
            className="mt-4 w-full sm:absolute sm:right-10 sm:top-24 sm:w-fit"
            size={isSm ? 'md' : 'md'}
            Icon={IconPlus}
            disabled={!studentAvailableFeatures?.canScheduleSession}
            onClick={handleOpenAddSession}
          >
            Create Schedule
          </Button>
        </Tooltip>
      </div>
    </>
  );
};

export default StudentHeader;
