import clsx from 'clsx';
import { useEffect, useState } from 'react';
import Avatar from 'src/components/Avatar';
import Button from 'src/components/Button';
import ErrorMessage from 'src/components/ErrorMessage';
import ExtraLabel from 'src/components/ExtraLabel';
import Switch from 'src/components/Switch';
import Tag from 'src/components/Tag';
import { Tooltip } from 'src/components/Tooltip';
import useSelectedStudent from 'src/hooks/useSelectedStudent';
import useTimeFormat from 'src/hooks/useTimeFormat';
import { IconEdit } from 'src/icons';
import { buildName } from 'src/logic/buildName';
import { Student, StudentBase } from 'src/pages/students/students.types';

type StudentFormProps = {
  selectedStudentId?: string;
  savedStudent?: StudentBase;
  onStudentSelect?: (student: Student) => void;
  onStudentClear?: () => void;
  isStudentLocked?: boolean;
  error?: string;
  disabled?: boolean;
};
const StudentForm = ({
  onStudentSelect,
  isStudentLocked: lockOnStudent,
  onStudentClear,
  selectedStudentId,
  savedStudent,
  error,
  disabled,
}: StudentFormProps) => {
  const [showInactiveStudents, setShowInactiveStudents] = useState(false);
  const { isFetching, isSuccess, selectedStudent, students } = useSelectedStudent(
    showInactiveStudents,
    selectedStudentId,
    savedStudent,
  );
  const { formatDate } = useTimeFormat();

  useEffect(() => {
    if (lockOnStudent && isSuccess && !selectedStudent && !showInactiveStudents) {
      setShowInactiveStudents(true);
    }
  }, [isSuccess, lockOnStudent, selectedStudent, showInactiveStudents]);

  if (isFetching && !selectedStudent) {
    return <div>Loading students</div>;
  }

  return (
    <div className="flex flex-wrap gap-2">
      {selectedStudent ? (
        <div className="flex w-full items-center gap-4">
          <Avatar
            size="2xl"
            firstName={selectedStudent.firstName}
            lastName={selectedStudent.lastName}
          />
          <div>
            <div className="mb-1 text-neutral-100 typography-heading-md">
              {buildName(selectedStudent)}
            </div>
            <div className="flex items-center gap-4 text-neutral-300 typography-body-sm">
              {selectedStudent.schoolName}
              <Tag preset="lightBlue" size="md">
                {selectedStudent.grade}
              </Tag>
            </div>
            <div className="mt-2 flex gap-2">
              <Tag preset="grayStroke" size="md">
                {selectedStudent.serviceType}
              </Tag>
              <Tag preset="grayStroke" size="md">
                {selectedStudent.hoursPerWeek}h / week
              </Tag>
            </div>
          </div>
          <div className="grow self-start justify-self-end">
            {!lockOnStudent && !disabled && (
              <Button
                Icon={IconEdit}
                preset="tertiary"
                className="ml-auto"
                size="sm"
                onClick={onStudentClear}
              />
            )}
          </div>
        </div>
      ) : (
        <div>
          <div className="mb-6 flex items-center gap-4">
            <ExtraLabel>Student</ExtraLabel>
            <Switch
              label="Show inactive"
              checked={showInactiveStudents}
              onCheckedChange={setShowInactiveStudents}
            />
          </div>
          <div className="flex flex-wrap gap-2">
            {students?.map((student) => {
              return (
                <Tooltip
                  side="bottom"
                  content={
                    student.hasConfiguredGoals
                      ? `${student.firstName} ${student.lastName} \n  ${formatDate(
                          student.startDate,
                        )} - ${formatDate(student.finishDate)}  \n  ${student.hoursPerWeek}h / week`
                      : `${student.firstName} ${student.lastName} \n Sessions cannot be scheduled prior to goal selection`
                  }
                  delayDuration={0}
                  className="w-72"
                  key={student.id}
                >
                  <div>
                    <Avatar
                      className={clsx(
                        !student.hasConfiguredGoals && 'rounded-full border-2 border-primary-400',
                      )}
                      isActive={student.isActive}
                      key={student.id}
                      firstName={student.firstName}
                      lastName={student.lastName}
                      onClick={() => student.hasConfiguredGoals && onStudentSelect?.(student)}
                    />
                  </div>
                </Tooltip>
              );
            })}
          </div>
        </div>
      )}
      <ErrorMessage>{error}</ErrorMessage>
    </div>
  );
};
export default StudentForm;
