import { isFuture } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import Tag from 'src/components/Tag';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import useTimeFormat from 'src/hooks/useTimeFormat';
import { buildName } from 'src/logic/buildName';

import { SingleStudentDetails } from '../singleStudent.types';
import { useLazyStudentEventsQuery } from '../singleStudentApi';

export type StudentHeaderParametersProps = {
  student: SingleStudentDetails;
  size: 'sm' | 'md';
};

const StudentHeaderParameters = ({ student, size }: StudentHeaderParametersProps) => {
  const [getEvents, { data: events = [], isUninitialized }] = useLazyStudentEventsQuery();
  const isNotFinished = useMemo(
    () => student && isFuture(new Date(student?.finishDate)),
    [student],
  );
  const { isSm } = useBreakpoint('sm');
  const { formatDateTime, formatDate } = useTimeFormat();
  const nextSchedule = useMemo(() => {
    return student.nextSessionDate
      ? formatDateTime(new Date(student.nextSessionDate))
      : 'No schedules';
  }, [student, formatDateTime]);
  const [maxHours, setMaxHours] = useState('');

  useEffect(() => {
    if (isUninitialized && isNotFinished) {
      getEvents({
        studentId: student.id,
        start: new Date().toISOString(),
        finish: student.finishDate,
        showOnlyMyClasses: false,
      });
    }
  }, [isUninitialized, student, isNotFinished, student.id, getEvents]);

  useEffect(() => {
    const maxHours = student.maxHours;

    if (!maxHours) {
      setMaxHours('');
    } else {
      setMaxHours(`Confirmed sessions: ${maxHours['used']}h / ${maxHours['total']}h`);
    }
  }, [student, setMaxHours]);

  const serviceTypeAndWeekCount = () => {
    return (
      <>
        <Tag preset="grayStroke" size={isSm && size !== 'sm' ? 'md' : 'sm'} className="w-fit">
          {student?.serviceType}
        </Tag>
        <Tag preset="grayStroke" size={isSm && size !== 'sm' ? 'md' : 'sm'} className="w-fit">
          {student?.hoursPerWeek}h / week
        </Tag>
      </>
    );
  };

  const confirmedCount = () => {
    return (
      <>
        {maxHours && (
          <Tag preset="grayStroke" size={isSm && size !== 'sm' ? 'md' : 'sm'}>
            {maxHours}
          </Tag>
        )}
      </>
    );
  };

  const supervisor = () => {
    return (
      <Tag preset="gray" size={isSm && size !== 'sm' ? 'md' : 'sm'} className="w-fit">
        Supervisor:{' '}
        {buildName({
          firstName: student?.supervisorFirstName,
          lastName: student?.supervisorLastName,
        })}
      </Tag>
    );
  };

  const nextSession = () => {
    return (
      <Tag
        preset="grayStroke"
        size={isSm && size !== 'sm' ? 'md' : 'sm'}
        className="mb-2 w-fit sm:mb-0"
      >
        Next session: {nextSchedule}
      </Tag>
    );
  };

  return (
    <div className="flex flex-col">
      <div className="flex gap-x-2">
        <Tag preset="lightBlue" size={isSm && size !== 'sm' ? 'md' : 'sm'}>
          {formatDate(new Date(student.startDate))} - {formatDate(new Date(student.finishDate))}
        </Tag>
        {isSm && serviceTypeAndWeekCount()}
      </div>
      <div className="flex gap-x-2 pt-2 sm:pt-4">
        {isSm && (
          <>
            {supervisor()}
            {nextSession()}
            {confirmedCount()}
          </>
        )}
        {!isSm && (
          <div className="flex-row">
            <div className="flex gap-x-2">
              {serviceTypeAndWeekCount()}
              {supervisor()}
            </div>
            <div className="flex flex-wrap gap-x-2 pt-2">
              {nextSession()}
              {confirmedCount()}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default StudentHeaderParameters;
