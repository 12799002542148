import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import Pagination from 'src/components/Pagination';
import Search from 'src/components/Search';
import Switch from 'src/components/Switch';
import { useAppDispatch, useAppSelector } from 'src/store';
import { getPageCount } from 'src/utils/pagination.utils';
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../../tailwind.config.cjs';
import SingleStudentLoadingCard from './SingleStudentLoadingCard';
import { useStudentsListQuery } from './studentsApi';
import StudentsFilters from './StudentsFilters';
import StudentsFiltersList from './StudentsFiltersList';
import StudentsList from './StudentsList';
import StudentsListEmpty from './StudentsListEmpty';
import {
  selectShowInactiveStudents,
  selectStudentsFilters,
  selectStudentsFiltersCount,
  selectStudentsPage,
  selectStudentsQueryString,
} from './studentsSelectors';
import {
  resetStudents,
  setShowInactiveStudents,
  setStudentsPage,
  setStudentsQuery,
} from './studentsSlice';

const Students = () => {
  const dispatch = useAppDispatch();
  const queryString = useAppSelector(selectStudentsQueryString);
  const page = useAppSelector(selectStudentsPage);
  const filters = useAppSelector(selectStudentsFilters);
  const filtersCount = useAppSelector(selectStudentsFiltersCount);
  const showInactive = useAppSelector(selectShowInactiveStudents);
  const [pageSize, setPageSize] = useState(9);
  const [searchWidth, setSearchWidth] = useState('w-12');
  const [active, setActive] = useState(false);
  const searchRef = useRef<HTMLDivElement>(null);
  const tailwind = resolveConfig(tailwindConfig);
  useEffect(() => {
    const getBreakpointValue = (value: string) => parseInt(value, 10);
    if (window.innerWidth >= getBreakpointValue(tailwind.theme.screens['2.5xl'])) {
      setPageSize(16);
    }
    if (window.innerWidth >= getBreakpointValue(tailwind.theme.screens['4xl'])) {
      setPageSize(20);
    }
    if (window.innerWidth >= getBreakpointValue(tailwind.theme.screens['4.5xl'])) {
      setPageSize(24);
    }
  }, [tailwind.theme.screens]);

  const { data, isFetching } = useStudentsListQuery({
    page,
    queryString,
    showInactive,
    pageSize: pageSize,
    ...filters,
  });

  useEffect(() => {
    return () => {
      dispatch(resetStudents());
    };
  }, [dispatch]);

  const pagesCount = getPageCount(data!);
  const firstVisibleStudentNumber = (page - 1) * (data?.pageSize || 0) + 1;

  const onChangeQuery: ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch(setStudentsQuery(e.target.value));
  };

  const onSetStudentsPage = (p: number) => {
    dispatch(setStudentsPage(p));
  };

  const onToggleShowInactive = (newValue: boolean) => dispatch(setShowInactiveStudents(newValue));
  const handleSearchClick = () => {
    setSearchWidth('w-auto ');
    setActive(true);
  };

  return (
    <div className="bg-neutral-950 p-4 sm:p-10">
      <div className="mb-6 flex items-center justify-between gap-4 sm:mb-10 md:items-start">
        <div>
          <div className="mb-2 text-neutral-100 typography-heading-xl">Students</div>
          <div className="text-neutral-300 typography-body-lg">
            Total: <span className="typography-loud-lg">{isFetching ? '' : data?.total || 0}</span>
          </div>
        </div>
        <div ref={searchRef} className="flex items-center gap-4">
          <Search
            size="md"
            value={queryString}
            onChange={onChangeQuery}
            onClick={handleSearchClick}
            active={active}
            className={`${searchWidth} h-12 min-w-12  rounded-lg border-0 bg-neutral-white shadow-elevation-1 transition duration-1000 md:w-auto md:shadow-none xl:w-80`}
            onBlur={() => {
              setActive(false);
              setSearchWidth('w-12');
            }}
          />
          <StudentsFilters />
          <Switch
            label="Show inactive"
            checked={showInactive}
            onCheckedChange={onToggleShowInactive}
            withFrame
          />
        </div>
      </div>
      <StudentsFiltersList />

      {isFetching ? (
        <div className="mb-8 grid grid-cols-1 gap-6 sm:grid-cols-3 2.5xl:grid-cols-4 4xl:grid-cols-5 4.5xl:grid-cols-6 5xl:grid-cols-7">
          {Array.from({ length: pageSize }, (_, indeks) => (
            <SingleStudentLoadingCard key={indeks} />
          ))}
        </div>
      ) : data?.items?.length ? (
        <>
          <StudentsList students={data?.items?.slice(0, pageSize) || []} />
          <div className="flex flex-col items-center justify-between gap-4 sm:flex-row">
            <Pagination
              activePage={page}
              pagesCount={pagesCount}
              onChangeActivePage={onSetStudentsPage}
              itemsPerPage={data.pageSize}
              totalSize={data.total}
              searchingItemsPlaceholder="students"
            />
          </div>
        </>
      ) : (
        <StudentsListEmpty areFiltersApplied={!!filtersCount || !!queryString?.length} />
      )}
    </div>
  );
};

export default Students;
